<template>
  <v-container>
    <v-row>
      <h1>Privaatsuspoliitika</h1>
      <p>
        Käesolev privaatsuspoliitika kirjeldab, kuidas Piletivahetus.ee töötleb
        isikuandmeid. Privaatsuspoliitika kohaldub, kui kasutate
        Piletivahetus.ee keskkonda või olete edastanud oma andmed
        Piletivahetus.ee veebivormide kaudu.
      </p>
      <h2>Milliseid isikuandmeid me kogume ja miks</h2>
      <br />
      <h3>Üldine</h3>
      <div>
        Veebivormide kaudu sisestatud andmeid töödeldakse eesmärgiga, et pakkuda
        piletivahetus.ee funktsionaalsusi. Piletimüüa, registeerides ja pileti
        üles panemisel nõustub, et tema andmed on meile kättesaadavad. Pileti
        ostja omalt poolt kinnitab info sisestamisel, et ta nõustub sisestatud
        info jagamisega teisele poolele, et tehinguprotsessiga edasi liikuda.
        Siinkohal on paslik mainida, et Piletivahetus.ee ei võta omale mingit
        vastutust tehingu toimumise või mitte toimumise kohta, sh kõikide muude
        asjaolude eest, mis tehingut puudutab.
      </div>
      <br />
      <h3>Küpsised</h3>
      <div>
        Küpsisteks nimetatakse tekstifaile, mille veebilehitseja salvestab Teie
        seadmesse veebilehte külastades. Piletivahetus.ee veebilehel kasutatavad
        küpsised jagunevad enda olemuselt kaheks:
      </div>
      <ul>
        <li>
          Seansiküpsised - Ajutised küpsised, mis kustutakse pärast
          veebibrauseri sulgemist.
        </li>
        <li>
          Püsiküpsised - Salvestatakse Teie arvutisse ning kasutatakse kasutaja
          anonüümseks tuvastamiseks hiljem, kui peaksite veebisaiti uuesti
          külastama.
        </li>
      </ul>
      Piletivahetus.ee kasutab küpsiseid, et parendada külastaja
      kasutajakogemust ning analüüsida veebilehe toiminguid, et neid
      järk-järgult täiustada ning parendada ja seeläbi kliendikogemust muuta
      paremaks. Meie mitmed küpsised on loodud mitmete erinevate
      teenusepakkujate poolt, läbi mille saame oma teenuseid pakkuda ja
      arendada. Teiste teenusepakkujate küpsiste reeglid on seatud
      teenustepakkujate poolt. Piletivahetus.ee lehel kasutatavad küpsised
      teenustepakkujate poolt on järgnevad:
      <ul>
        <li>
          Google - Google'i privaatsuspoliitika
          (https://policies.google.com/privacy?hl=et&amp;gl=ee)
        </li>
        <li>
          Facebook - Facebooki privaatsuspoliitika
          (https://www.facebook.com/policies/cookies/)
        </li>
        <li>
          Mouseflow - Mouseflow privaatsuspoliitika
          (https://mouseflow.com/privacy/)
        </li>
      </ul>
      Teised teenusepakkujatel on võimalus koguda läbi paigaldatud küpsiste
      informatsiooni veebilehe külastaja kasutamise, harjumuste kohta, läbi
      mille on võimalik pakkuda kasutajale sihitud (personaalset) reklaami.
      &nbsp; Piletivahetus.ee veebilehe kasutajad loetakse küpsiste kasutamisega
      nõustunuks, kui nad nõustuvad küpsiste kasutamisega nii veebilehitseja
      seadistuses kui ka esmaskordsel registreerimisel Piletivahetus.ee
      keskkonnas. Kui Te küpsiste kasutamisega ei nõustu ja keelate selle
      veebilehitseja seadistustes, võivad mitmed Piletivahetuse.ee veebilehe
      teenused ja funktsioonid olla piiratud või mitte töötada.
      <h2>Millised on õigused enda andmetele</h2>
      Kui olete varasemalt esitanud meile andmeid läbi kontaktvormi või
      lepingust tulenevast kohustustest, võid taotleda faili meie poolt enda
      kohta käivate isikuandmetega, sh enda poolt sisestatud info. Võid ka
      taotleda kõigi enda kohta käivate andmete kustutamist. See ei puuduta
      andmeid, mida oleme kohustatud säilitama halduse, seaduste või turvalisuse
      vajadustest lähtuvalt.
      <h2>Täiendav info</h2>
      <div></div>
      <div>
        Kui Teil on küsimusi seoses Teie isikuandmete töötlemisega või soovite
        rakendada enda õiguseid enda andmete suhtes, palun võtke meiega ühendust
        e-posti aadressil info@piletivahetus.ee
      </div>
      Käesolevaid tingimusi võidakse muuta ilma sellest ette teatamata
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'PrivacyPolicy'
}
</script>
